import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'


function Blog ({ data }) {

  return (
    <Layout>

    <section className="blog">
      <div className="container">
        <div className="row">

            <div className="col-xl-6 offset-xl-3 col-lg-12 col-md-12 col-12 text-center">
                <h1>Blog</h1>
                <span className="intro_p">Focus on your business value – with a turnkey solution for granular access control with state-of-the-art security.</span>
            </div>

            <div className="col-12 text-center tags-wrapper">
                <div class="d-flex flex-wrap justify-content-center">
                        <Link to="/blog">
                            <button className="btn btn-tag">All</button>
                        </Link>

                        {data.allStrapiTag.nodes.map((tag, id) => (
                            <Link to={`/blog/${tag.slug}`} key={id}>
                                <button className={
                                    tag.slug === data.strapiTag.slug 
                                    ? 
                                    "btn btn-tag tag-active" 
                                    : 
                                    "btn btn-tag" 
                                    }>
                                    {tag.title}
                                </button>
                            </Link>
                            ))}
                </div>
            </div>

            <div className="row blogs-wrapper">

                {data.allStrapiPost.nodes.map((post, id) => (
                    <div className="col-md-4 col-sm-6 col-12 article-item" key={id}>
                    <Link to={`/blog/${post.slug}`}>
                        <GatsbyImage image={getImage(post.cover?.localFile)} className="img-fluid" alt=""/>
                    </Link>
                    <span className="blog-date">{post.date}</span>
                    <Link to={`/blog/${post.slug}`} className="article-link">{post.title}</Link>
                    </div>
                ))}

            </div>

            
        </div>
      </div>
    </section>

  </Layout>

  )
}

export const Head = () => <Seo title="iamcore Blog | Articles by tag"/>

export default Blog

export const pageQuery = graphql`
query ($slug: String) {
    allStrapiPost(filter: {tags: {elemMatch: {slug: {eq: $slug}}}}, sort: {date: DESC}) {
      nodes {
        slug
        title
        date(formatString: "DD MMM YYYY")
        preview
        cover {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    strapiTag(slug: {eq: $slug}) {
        slug
      }
    allStrapiTag {
        nodes {
          title
          slug
        }
      }
  }
`
